var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',{attrs:{"fluid":""}},[_c('body',{staticClass:"application application-offset"},[_c('div',{staticClass:"container-fluid container-application"},[_c('mainMenu'),_vm._v(" "),_c('div',{staticClass:"main-content position-relative"},[_c('mainNav'),_vm._v(" "),_c('div',{staticClass:"page-content index"},[_c('div',{staticClass:"page-title"},[_c('div',{staticClass:"row justify-content-between align-items-center"},[_c('div',{staticClass:"col-md-6 mb-3 mb-md-0",staticStyle:{"z-index":"5"}},[_c('h5',{staticClass:"h3 font-weight-400 mb-0 text-white"},[_vm._v("嗨，"+_vm._s(_vm.userName)+"!")]),_c('span',{staticClass:"text-sm text-white opacity-8"},[_vm._v("祝你有個美好的一天!")])])])]),_c('div',{staticClass:"card",staticStyle:{"z-index":"2"}},[_c('div',{staticClass:"card-header actions-toolbar border-0"},[_c('div',{staticClass:"row justify-content-between align-items-center"},[_c('div',{staticClass:"col",staticStyle:{"padding-top":"0px","padding-bottom":"0px"}},[_c('h2',{staticClass:"d-inline-block mb-0 card-title"},[_vm._v("場域人員管理")])])])]),_c('v-form',{ref:"form",staticClass:"px-12"},[_c('v-row',[_c('v-subheader',[_vm._v(" 場域人員管理 ")])],1),_c('v-row',[_c('v-divider',{staticClass:"my-0"})],1),_c('v-row',[_c('v-col',[_c('v-subheader',[_vm._v(" 事業單位人員 ")])],1),_c('v-col',{attrs:{"sm":"1"}},[_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"#37906C"},on:{"click":_vm.addAll}},[_vm._v(" 全選 ")])],1)],1),_c('v-row',[_c('v-divider',{staticClass:"my-0"})],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"mx-12",attrs:{"headers":_vm.headers,"items":_vm.memberList,"items-per-page":_vm.listPageSize,"hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.jobSiteMemberAdd",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.toAdd(item)}}},[_vm._v("mdi-account-plus")])]}},{key:"item.photo1",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"avatar-group"},[_c('a',{staticClass:"avatar rounded-circle avatar-sm",attrs:{"href":"#"}},[_c('v-img',{model:{value:(item.photoShow),callback:function ($$v) {_vm.$set(item, "photoShow", $$v)},expression:"item.photoShow"}})],1)])]}},{key:"item.memberPropertyList",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.memberPropertyList,"item-text":"text","item-value":"value","attach":"","chips":"","label":"人員屬性","multiple":"","disabled":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":"primary"}},[_vm._v(_vm._s(item.text))])]}}],null,true),model:{value:(item.orgnizationMember.memberPropertyList),callback:function ($$v) {_vm.$set(item.orgnizationMember, "memberPropertyList", $$v)},expression:"item.orgnizationMember.memberPropertyList"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('v-subheader',[_vm._v(" 已選取人員 ")])],1),_c('v-col',{attrs:{"sm":"1"}},[_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"#37906C"},on:{"click":_vm.delAll}},[_vm._v(" 全刪 ")])],1)],1),_c('v-row',[_c('v-divider',{staticClass:"my-0"})],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"mx-12",attrs:{"headers":_vm.headers2,"items":_vm.selectDatalist,"items-per-page":_vm.listPageSize,"min-height":"200px","hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.jobSiteMemberDel",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.toDel(item)}}},[_vm._v("mdi-account-remove")])]}},{key:"item.memberPropertyList",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.memberPropertyList,"item-text":"text","item-value":"value","attach":"","chips":"","label":"人員屬性","multiple":"","readonly":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":"primary"}},[_vm._v(_vm._s(item.text))])]}}],null,true),model:{value:(item.memberPropertyList),callback:function ($$v) {_vm.$set(item, "memberPropertyList", $$v)},expression:"item.memberPropertyList"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-divider',{staticClass:"my-0"}),_c('br')],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"align":"center"}},[_c('v-btn',{staticClass:"mr-4 white--text",attrs:{"min-width":"100","color":"#37906C","large":""},on:{"click":_vm.submitData}},[_vm._v(" 儲存 ")]),_c('v-btn',{staticClass:"mr-4 white--text",attrs:{"min-width":"100","color":"#4D4D4D","large":""},on:{"click":_vm.toCancel}},[_vm._v(" 取消 ")])],1)],1),_c('br')],1)],1)]),_c('mainFooter')],1)],1)])]),_c('loadingMask',{staticStyle:{"z-index":"9999"},attrs:{"showLoading":_vm.isLoading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }