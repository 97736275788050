<template>
  <v-app>
    <v-container fluid>
        <body class="application application-offset">
            <div class="container-fluid container-application">
                <mainMenu /> <!-- ==== 左選單 ==== -->

                <div class="main-content position-relative">
                    <mainNav /> <!-- ==== Top選單 ==== -->

                    <div class="page-content index">
        
                        <!-- Page title -->
                        <div class="page-title">
                            <div class="row justify-content-between align-items-center">
                                <div class="col-md-6 mb-3 mb-md-0" style="z-index:5">
                                    <h5 class="h3 font-weight-400 mb-0 text-white">嗨，{{userName}}!</h5>
                                    <span class="text-sm text-white opacity-8">祝你有個美好的一天!</span>
                                </div>
                            </div>
                        </div>

                        <div class="card" style="z-index: 2;">
                            <div class="card-header actions-toolbar border-0">
                                <div class="row justify-content-between align-items-center">
                                    <div class="col" style="padding-top: 0px;padding-bottom: 0px;">
                                        <h2 class="d-inline-block mb-0 card-title">場域人員管理</h2>
                                    </div>
                                </div>
                            </div>
                            <!-- ==========主要內容區========== -->
                            <v-form ref="form" class="px-12">
                              <v-row>
                                <v-subheader>
                                  場域人員管理
                                </v-subheader>
                              </v-row>
                              <v-row>
                                <v-divider class="my-0"></v-divider>
                              </v-row>

                              <v-row>
                                <v-col>
                                  <v-subheader>
                                    事業單位人員
                                  </v-subheader>
                                </v-col>
                                <v-col sm="1">
                                  <v-btn
                                    class="ma-2 white--text"
                                    color="#37906C"
                                    @click="addAll"
                                  >
                                    全選
                                  </v-btn>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-divider class="my-0"></v-divider>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-data-table
                                      :headers="headers"
                                      :items="memberList"
                                      :items-per-page="listPageSize"
                                      hide-default-footer
                                      disable-sort
                                      class="mx-12"> 
                                          <template v-slot:[`item.jobSiteMemberAdd`]={item}>
                                              <v-icon @click.stop="toAdd(item)">mdi-account-plus</v-icon>
                                          </template>
                                          <template v-slot:[`item.photo1`]={item}>
                                            <div class="avatar-group">
                                              <a href="#" class="avatar rounded-circle avatar-sm">
                                                  <v-img v-model="item.photoShow"></v-img>
                                              </a>
                                            </div>
                                          </template>
                                          <template v-slot:[`item.memberPropertyList`]={item}>
                                            <v-select
                                              v-model="item.orgnizationMember.memberPropertyList"
                                              :items="memberPropertyList"
                                              item-text="text"
                                              item-value="value"
                                              attach
                                              chips
                                              label="人員屬性"
                                              multiple
                                              disabled
                                            >
                                              <template #selection="{ item }">
                                                <v-chip class="ma-2" color="primary">{{item.text}}</v-chip>
                                              </template>
                                            </v-select>
                                          </template>
                                  </v-data-table>
                                </v-col>
                              </v-row>

                              <v-row>
                                <v-col>
                                  <v-subheader>
                                    已選取人員
                                  </v-subheader>
                                </v-col>
                                <v-col sm="1">
                                  <v-btn
                                    class="ma-2 white--text"
                                    color="#37906C"
                                    @click="delAll"
                                  >
                                    全刪
                                  </v-btn>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-divider class="my-0"></v-divider>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-data-table
                                      :headers="headers2"
                                      :items="selectDatalist"
                                      :items-per-page="listPageSize"
                                      min-height="200px"
                                      hide-default-footer
                                      disable-sort
                                      class="mx-12"> 
                                          <template v-slot:[`item.jobSiteMemberDel`]={item}>
                                              <v-icon @click.stop="toDel(item)">mdi-account-remove</v-icon>
                                          </template>
                                          <template v-slot:[`item.memberPropertyList`]={item}>
                                            <v-select
                                              v-model="item.memberPropertyList"
                                              :items="memberPropertyList"
                                              item-text="text"
                                              item-value="value"
                                              attach
                                              chips
                                              label="人員屬性"
                                              multiple
                                              readonly
                                            >
                                              <template #selection="{ item }">
                                                <v-chip class="ma-2" color="primary">{{item.text}}</v-chip>
                                              </template>
                                            </v-select>
                                          </template>
                                  </v-data-table>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-divider class="my-0"></v-divider>
                                <br/>
                              </v-row>
                              <v-row justify="center" > 
                                  <v-col align="center">
                                    <v-btn class="mr-4 white--text" min-width="100" color="#37906C" large @click="submitData">
                                      儲存
                                    </v-btn>
                                    <v-btn class="mr-4 white--text" min-width="100" color="#4D4D4D" large @click="toCancel">
                                      取消
                                    </v-btn>
                                  </v-col>
                              </v-row>
                              <br/>
                            </v-form>
                            <!-- ==========主要內容區========== -->
                        </div>

                    </div>

                    <mainFooter /><!-- Footer -->
                    
                </div>
            </div>
        </body>
    </v-container>
    <loadingMask :showLoading="isLoading"  style="z-index:9999"></loadingMask>
  </v-app>
</template>

<script>
import mainMenu from '@/components/mainMenu.vue'
import mainNav from '@/components/mainNav.vue'
import mainFooter from '@/components/mainFooter.vue'
import commonMixin from '@/utils/commonMixin';
import loadingMask from '@/components/public/loadingMask.vue'
import {getJobSiteMemberList, selectMember, saveSelectMember, downloadFile, getSysParamListByCode} from "@/api/hyirecoApi";

export default {
  name: 'orgnizationList',
  mixins: [commonMixin],
  created(){
    this.authValidate();
    //console.log("loginAuth" + JSON.stringify(this.loginAuth));
    this.auth = {
      headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getTokenWithSession(),
      }
    };
    this.getMemberProperty();
  },
  destroyed() {
    clearInterval(this.interval);
  },
  mounted(){
    this.userName = this.loginAuth.memberName;
    this.getData();
  },
  computed: {
    headers() {
      return [
        { text: '加入', value: 'jobSiteMemberAdd', class: "subtitle-2 font-weight-black",width:80, },
        { text: '編號', value: 'orgnizationMember.orgmCode', class: "subtitle-2 font-weight-black",width:100,},
        { text: '姓名', value: 'orgnizationMember.name', class: "subtitle-2 font-weight-black",},
        { text: '人員屬性', value: 'memberPropertyList', class: "subtitle-2 font-weight-black",width:200,},
        //{ text: '照片', value: 'orgnizationMember.photo1', class: "subtitle-2 font-weight-black",width:10,},
      ]
    },
    headers2() {
      return [
        { text: '刪除', value: 'jobSiteMemberDel', class: "subtitle-2 font-weight-black",width:80, },
        { text: '編號', value: 'orgnizationMember.orgmCode', class: "subtitle-2 font-weight-black",width:100,},
        { text: '姓名', value: 'orgnizationMember.name', class: "subtitle-2 font-weight-black",},
        { text: '人員屬性', value: 'memberPropertyList', class: "subtitle-2 font-weight-black",width:200,},
        //{ text: '照片', value: 'orgnizationMember.photo1', class: "subtitle-2 font-weight-black",width:10,},
      ]
    },
  },
  methods: {
    getMemberProperty: function(){
      var vm = this;
      
      getSysParamListByCode("people",vm.auth).then(function(response) {
        if ("200" == response.status) { 
          var responseData = response.data;
          if (responseData.statusCode === "1") {
            responseData.sysParam.childParamList.forEach(function(para){
              var tmpArr =  para.childParamList.map(function(child){
                              var item = new Object();
                              item.text = para.name + "-" + child.name;
                              item.value = child.code;
                              return item;
                            });
              //console.log("tmpArr = " + JSON.stringify(tmpArr));
              vm.memberPropertyList = vm.memberPropertyList.concat(tmpArr);
            });
          }
        }
      });
    },
    getData: function(){
      this.isLoading = true;
      var vm = this;
      getJobSiteMemberList(vm.$route.params.jsId, vm.auth).then(function(response) {
        if ("200" == response.status) { 
          var responseData = response.data;
          //console.log("getJobSiteMemberList responseData = " + JSON.stringify(responseData));
          vm.selectDatalist = responseData.jobSiteMemberList;
          if (typeof vm.selectDatalist === "undefined") {
            vm.selectDatalist = [];
          }
          console.log("vm.selectDatalist = " + JSON.stringify(vm.selectDatalist));
          vm.getMemberList();
        }
      });
      this.isLoading = false;
    },
    getMemberList: function(){
      this.isLoading = true;
      var vm = this;
      selectMember(vm.$route.params.orgId, vm.auth).then(function(response) {
        if ("200" == response.status) { 
          var responseData = response.data;

          if (typeof responseData.orgnizationMemberList === "undefined") {
            vm.memberList = [];
          }

          vm.memberList = responseData.orgnizationMemberList.filter(function (item) {
            return !vm.selectDatalist.some(function(item2){
              return item.orgmId == item2.orgmId;
            });
          }).map(function(item){
            var obj = new Object;
            obj.jsId = vm.$route.params.jsId;
            obj.orgmId = item.orgmId;
            obj.memberPropertyList = item.memberPropertyList;
            obj.orgnizationMember = item;
            return obj;
          });
          //console.log("selectMember = " + JSON.stringify(vm.memberList));
          //vm.getPhotoList();
        }
      });
      
      this.isLoading = false;
    },
    toAdd: function(item) {
      var vm = this;
      vm.memberList.splice(this.memberList.indexOf(item),1);
      vm.selectDatalist.push(item);
    },
    addAll: function() {
      var vm = this;
      vm.selectDatalist = vm.selectDatalist.concat(vm.memberList);
      vm.memberList = [];
    },
    toDel: function(item) {
      var vm = this;
      vm.selectDatalist.splice(vm.selectDatalist.indexOf(item),1);
      vm.memberList.push(item);
    },
    delAll: function() {
      var vm = this;
      vm.memberList = vm.selectDatalist.concat(vm.memberList);
      vm.selectDatalist = [];
    },
    submitData: function() {
      var vm = this;
      vm.isLoading = true;
      vm.saveStatus = true;

      var dataObj = new Object();
      dataObj.jsId = vm.$route.params.jsId;
      dataObj.jobSiteMemberList = vm.selectDatalist;
    
      //console.log("dataObj = " + JSON.stringify(dataObj));
      saveSelectMember(dataObj, vm.auth).then(function(response) {
          if ("200" == response.status) { 
            var responseData = response.data;
            vm.$swal(responseData.message);
            if (responseData.statusCode === "1") {
              vm.toCancel();
            }
            //console.log("responseData = " + JSON.stringify(response));
            vm.saveStatus = false;
            vm.isLoading = false;
          }
      });
    },
    toCancel: function () {
      this.$router.push({ path: "/jobSite/jobSiteList" });
    },
    getPhotoList: function () {
      var vm = this;
      vm.memberList.forEach(function (item){
        //console.log("item : " + JSON.stringify(item));
        var picAuth = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + vm.getTokenWithSession(),
          },
          responseType: 'blob',
        };
        downloadFile(item.orgnizationMember.photo, picAuth).then(function(response) {
          if ("200" == response.status) {
            var blob=new Blob([response.data]);
            vm.blobToDataURI(blob,  function (data) {
                item.photoShow = data;
            });
          }
        });
      });
    },
  },
  watch: {
  },
  data: () => ({
    userName: "",
    auth: {},
    isLoading: false, //loading畫面的開關
    listPageSize: 999,
    selectDatalist: [],
    memberList:[],
    memberPropertyList:[],
  }),
  components: {
    mainMenu,
    mainNav,
    mainFooter,
    loadingMask,
  },
}
</script>

<style scoped>
.v-input__slot > .v-label
{
    margin-bottom: 0px;
}
.v-subheader {
    color: rgb(34, 34, 34) !important;
    font-size: large;
}
</style>